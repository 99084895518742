import React, { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import LoadingSpinner from '@/shared/components/LoadingSpinner';
import { processorClient } from '@/shared/graphql/client';
import {
  type GetChannelSearchResultsQuery,
  type GetIndexInBlockSearchResultsQuery,
  type GetNumericSearchResultsQuery,
  type GetStringSearchResultsQuery,
} from '@/shared/graphql/generated/graphql';
import { useClampedValue, useDebouncedEffect } from '@/shared/hooks';
import {
  BlockOutsideIcon,
  NoSearchIcon,
  BoxIcon,
  BrokerIcon,
  ContractSuccessIcon,
  HourglassIcon,
  NodeIcon,
  QRIcon,
  SearchIcon,
  SwapIcon,
  UserIcon,
  ArrowBackIcon,
  CloseIcon,
} from '@/shared/icons/small';
import { isTruthy, parseChannelId } from '@/shared/utils';
import { type ChainflipChain } from '@/shared/utils/chainflip';
import {
  getChannelSearchResults,
  getIndexInBlockSearchResults,
  getNumericSearchResults,
  getStringSearchResults,
} from '../../queries/search';

const ActionButton = ({
  onClick,
  children,
  className,
}: {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}) => (
  <button
    className={classNames(
      'flex h-[20px] w-[20px] items-center justify-center rounded-sm border border-cf-gray-5 bg-cf-gray-4 text-cf-light-2 transition-colors hover:bg-cf-gray-5 hover:text-cf-light-3',
      className,
    )}
    type="button"
    onClick={onClick}
  >
    {children}
  </button>
);

const useSearchResults = (searchText: string) =>
  useQuery({
    queryKey: ['search', searchText],
    placeholderData: keepPreviousData,
    queryFn: (): Promise<
      | GetChannelSearchResultsQuery
      | GetIndexInBlockSearchResultsQuery
      | GetNumericSearchResultsQuery
      | GetStringSearchResultsQuery
    > | null => {
      if (!searchText) return null;

      const channelId = parseChannelId(searchText);
      if (channelId) {
        return processorClient.request(getChannelSearchResults, {
          issuedBlockId: channelId.issuedBlockId,
          chain: channelId.sourceChain as ChainflipChain,
          channelId: channelId.channelId,
        });
      }

      if (/^[0-9]+-[0-9]+$/.test(searchText)) {
        return processorClient.request(getIndexInBlockSearchResults, {
          blockId: Number(searchText.split('-')[0]),
          indexInBlock: Number(searchText.split('-')[1]),
        });
      }

      if (/^[0-9][0-9,]+$/.test(searchText)) {
        // allow comma for searching block numbers from polkadotjs (2,233,241)
        const numericString = searchText.replace(/,/g, '');
        const numberValue = Number(numericString);
        return processorClient.request(getNumericSearchResults, {
          intValue: Number.isSafeInteger(numberValue) ? numberValue : -1,
          bigIntValue: numericString,
        });
      }

      return processorClient.request(getStringSearchResults, {
        searchString: searchText,
      });
    },
  });

const extractResults = (data: ReturnType<typeof useSearchResults>['data']) => {
  const results = [];
  if (data && 'swaps' in data) {
    results.push(
      ...(data.swaps?.nodes.map((node) => ({
        category: 'Swaps',
        icon: <SwapIcon className="transition-colors group-[.cf-highlight]/item:text-cf-blue-4" />,
        label: `Swap ${node.nativeId}`,
        path: `/swaps/${node.nativeId}`,
      })) ?? []),
    );
  }
  if (data && 'blocks' in data) {
    results.push(
      ...(data.blocks?.nodes.map((node) => ({
        category: 'Blocks',
        icon: <BoxIcon className="transition-colors group-[.cf-highlight]/item:text-cf-green-3" />,
        label: `Block ${node.id}`,
        path: `/blocks/${node.id}`,
      })) ?? []),
    );
  }
  if (data && 'accounts' in data) {
    results.push(
      ...(data.accounts?.nodes.map((node) => {
        if (node.role === 'VALIDATOR') {
          return {
            category: 'Validators',
            icon: (
              <NodeIcon className="transition-colors group-[.cf-highlight]/item:text-cf-orange-2" />
            ),
            label: `Validator ${node.idSs58}`,
            path: `/validators/${node.idSs58}`,
          };
        }
        if (node.role === 'BROKER') {
          return {
            category: 'Brokers',
            icon: (
              <BrokerIcon className="transition-colors group-[.cf-highlight]/item:text-cf-pink-1" />
            ),
            label: `Broker ${node.idSs58}`,
            path: `/brokers/${node.idSs58}`,
          };
        }
        if (node.role === 'LIQUIDITY_PROVIDER') {
          return {
            category: 'Liquidity Providers',
            icon: (
              <UserIcon className="transition-colors group-[.cf-highlight]/item:text-cf-blue-4" />
            ),
            label: `Liquidity Provider ${node.idSs58}`,
            path: `/lps/${node.idSs58}`,
          };
        }

        return undefined;
      }) ?? []),
    );
  }
  if (data && 'extrinsics' in data) {
    results.push(
      ...(data.extrinsics?.nodes.map((node) => ({
        category: 'Extrinsics',
        icon: (
          <ContractSuccessIcon className="transition-colors group-[.cf-highlight]/item:text-cf-blue-2" />
        ),
        label: `Extrinsic ${node.blockId}-${node.indexInBlock}`,
        path: `/extrinsics/${node.blockId}-${node.indexInBlock}`,
      })) ?? []),
    );
  }
  if (data && 'events' in data) {
    results.push(
      ...(data.events?.nodes.map((node) => ({
        category: 'Events',
        icon: (
          <BlockOutsideIcon className="transition-colors group-[.cf-highlight]/item:text-cf-blue-2" />
        ),
        label: `Event ${node.blockId}-${node.indexInBlock}`,
        path: `/events/${node.blockId}-${node.indexInBlock}`,
      })) ?? []),
    );
  }
  if (data && 'epochs' in data) {
    results.push(
      ...(data.epochs?.nodes.map((node) => ({
        category: 'Epochs',
        icon: (
          <HourglassIcon className="transition-colors group-[.cf-highlight]/item:text-cf-orange-1" />
        ),
        label: `Authority Set ${node.id}`,
        path: `/authority-sets/${node.id}`,
      })) ?? []),
    );
  }
  if (data && 'swapChannels' in data) {
    results.push(
      ...(data.swapChannels?.nodes.map((node) => ({
        category: 'Channels',
        icon: <QRIcon className="transition-colors group-[.cf-highlight]/item:text-cf-blue-3" />,
        label: `Swap Channel ${node.issuedBlockId}-${node.sourceChain}-${node.channelId}`,
        path: `/channels/${node.issuedBlockId}-${node.sourceChain}-${node.channelId}`,
      })) ?? []),
    );
  }
  if (data && 'lpChannels' in data) {
    results.push(
      ...(data.lpChannels?.nodes.map((node) => ({
        category: 'Channels',
        icon: <QRIcon className="transition-colors group-[.cf-highlight]/item:text-cf-blue-3" />,
        label: `Liquidity Channel ${node.issuedBlockId}-${node.chain}-${node.channelId}`,
        path: `/liquidity-channels/${node.issuedBlockId}-${node.chain}-${node.channelId}`,
      })) ?? []),
    );
  }

  if (data && 'txRefs' in data) {
    data.txRefs?.nodes.forEach((node) => {
      node.broadcast?.egress.nodes.forEach((egress) =>
        egress.swapRequests.nodes.forEach((swapRequest) =>
          results.push({
            category: 'Swaps',
            icon: (
              <SwapIcon className="transition-colors group-[.cf-highlight]/item:text-cf-blue-4" />
            ),
            label: `Swap ${swapRequest.nativeId}`,
            path: `/swaps/${swapRequest.nativeId}`,
          }),
        ),
      );
      if (node.lpDeposit) {
        const { channel } = node.lpDeposit;
        results.push({
          category: 'Channels',
          icon: <QRIcon className="transition-colors group-[.cf-highlight]/item:text-cf-blue-3" />,
          label: `Liquidity Channel ${channel.issuedBlockId}-${channel.chain}-${channel.channelId}`,
          path: `/liquidity-channels/${channel.issuedBlockId}-${channel.chain}-${channel.channelId}`,
        });
      }
      if (node.swap) {
        results.push({
          category: 'Swaps',
          icon: (
            <SwapIcon className="transition-colors group-[.cf-highlight]/item:text-cf-blue-4" />
          ),
          label: `Swap ${node.swap.nativeId}`,
          path: `/swaps/${node.swap.nativeId}`,
        });
      }
    });
  }

  return results.filter(isTruthy);
};

const SearchBar = () => {
  const router = useRouter();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [resultsRef, setResultsRef] = useState<HTMLDivElement | null>(null);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  useDebouncedEffect(() => setDebouncedSearchText(searchText), [searchText], searchText ? 300 : 0); // update immediately if user clears input

  const { isFetching, data } = useSearchResults(debouncedSearchText);
  const results = extractResults(data);
  const displayDropdown = Boolean(debouncedSearchText) && Boolean(data); // hide dropdown until server data is present

  const [activeResultIndex, setActiveResultIndex] = useClampedValue({
    start: 0,
    min: 0,
    max: Math.max(0, results.length - 1),
  });

  // observe height of result list to animate hight changes
  const [resultsHeight, setResultsHeight] = useState<number>();
  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      setResultsHeight(entry.borderBoxSize[0]?.blockSize);
    });
    if (resultsRef) observer.observe(resultsRef);

    return () => observer.disconnect();
  }, [resultsRef]);

  const onEscape = () => {
    if (searchText) {
      setSearchText('');
    } else {
      inputRef?.blur();
    }
  };
  const onEnter = () => {
    if (results[activeResultIndex]) {
      router.push(results[activeResultIndex].path);
    }
  };

  return (
    // make div focusable with tabIndex to allow for clicking button in dropdown on safari
    <div className="group/component relative w-full text-14" tabIndex={-1}>
      <div
        className={classNames(
          'flex w-full rounded-md border border-cf-gray-4 bg-cf-gray-2',
          displayDropdown && 'group-focus-within/component:rounded-b-none',
        )}
      >
        {/* use 16px font size on mobile to prevent annoying autozoom on ios when focusing input */}
        <input
          className="w-0 grow truncate rounded-md bg-cf-gray-2 px-4 py-2.5 text-16 text-cf-light-3 outline-none md:text-14"
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              onEscape();
            } else if (e.key === 'Enter') {
              onEnter();
            } else if (e.key === 'ArrowDown') {
              setActiveResultIndex((i) => i + 1);
              e.preventDefault(); // prevent changing cursor position
            } else if (e.key === 'ArrowUp') {
              setActiveResultIndex((i) => i - 1);
              e.preventDefault(); // prevent changing cursor position
            }
          }}
          ref={setInputRef}
        />
        <div
          className={classNames(
            'pointer-events-none absolute inset-0',
            searchText ? 'opacity-0' : 'opacity-100 group-focus-within/component:opacity-0',
          )}
        >
          <div className="absolute inset-0 flex items-center space-x-1 truncate whitespace-nowrap px-4 text-cf-light-1 transition-colors group-hover/component:text-cf-light-3">
            <div>
              <SearchIcon className="h-[20px] w-[20px]" />
            </div>
            <span className="pointer-events-none w-full truncate">Search for anything...</span>
          </div>
        </div>
        <div className="flex items-center gap-x-2 pr-2">
          {isFetching && <LoadingSpinner className="!h-[16px] !w-[16px]" />}
          {Boolean(searchText) && (
            <ActionButton onClick={onEscape}>
              <CloseIcon />
            </ActionButton>
          )}
          {Boolean(results.length) && (
            <ActionButton onClick={onEnter} className="hidden group-focus-within/component:flex">
              <ArrowBackIcon className="h-[14px] w-[14px] -scale-y-100" />
            </ActionButton>
          )}
        </div>
      </div>

      <div
        className={classNames(
          'absolute z-10 hidden w-full overflow-hidden rounded-b-md border border-t-0 border-cf-gray-4 bg-cf-gray-3-5 transition-[height] duration-200 ease-[cubic-bezier(0.23,1,0.32,1)]',
          displayDropdown && 'group-focus-within/component:block',
        )}
        style={{ height: resultsHeight }}
      >
        <div className="flex w-full flex-col gap-y-1 p-4" ref={setResultsRef}>
          {results.map((result, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              {(index === 0 || result.category !== results[index - 1].category) && (
                <div className="text-12 font-medium text-cf-light-1">{result.category}</div>
              )}
              <button
                type="button"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey) {
                    window.open(result.path);
                  } else {
                    router.push(result.path);
                  }
                }}
                onMouseEnter={() => setActiveResultIndex(index)}
                onFocus={() => setActiveResultIndex(index)}
                className={classNames(
                  'group/item flex flex-row items-center gap-x-2 rounded-md border border-transparent p-2 transition-colors',
                  index === activeResultIndex && 'cf-highlight !border-cf-gray-5 bg-cf-gray-4',
                )}
              >
                <div className="rounded-[4px] border border-cf-gray-5 p-0.5">{result.icon}</div>
                <div className="truncate whitespace-nowrap text-14 text-cf-light-3 transition-colors group-[.cf-highlight]/item:text-cf-white">
                  {result.label}
                </div>
              </button>
            </Fragment>
          ))}
          {!results.length && (
            <div className="flex flex-col items-center gap-1.5 p-4 text-12">
              <NoSearchIcon className="block text-cf-light-2" />
              <div className="text-center text-cf-light-2">
                Nothing found for <span className="text-cf-white">{debouncedSearchText}</span>.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
